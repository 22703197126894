import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(theme => ({
  root: {
    position: 'sticky',
    top: 0,
    height: '100vh',
    borderRight: `1px solid ${theme.colors.grey[70]}`,
    background: theme.palette.white,
    padding: '24px 16px',
    zIndex: 5,
    overflowY: 'auto',
  },
  sidebarLogo: {
    paddingLeft: 12,
  },
  sidebarContent: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      borderBottom: `1px solid ${theme.colors.grey[70]}`,
      '&:last-child': {
        border: 'none',
      },
    },
  },
  sidebarGroup: {
    padding: '8px 0',
    '&:first-child': {
      paddingTop: 0,
    },
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    padding: 12,
    gap: 12,
    borderRadius: 16,
    cursor: 'pointer',
    transition: 'all ease .3s',
    color: theme.colors.grey[10],
    position: 'relative',
    '& .active': {
      opacity: 0,
    },
    '&:active, &:hover': {
      '& $subMenuWrapper': {
        transition: 'all ease 250ms',
        display: 'flex',
        left: 240,
      },
    },
    '&:active, &:hover, &.current': {
      '&:not(.current)': {
        background: theme.colors.grey[95],
      },
      '& svg': {
        '& path': {
          transition: 'all ease .3s',
        },
        '& .active': {
          opacity: 1,
        },
        '& .default': {
          opacity: 0,
        },
      },
    },
  },
  menuIcon: {
    flexShrink: 0,
    width: 24,
    height: 24,
  },
  arrowIcon: {
    marginLeft: 'auto',
  },
  subMenuWrapper: {
    display: 'none',
    height: '100vh',
    paddingLeft: 16,
    position: 'fixed',
    width: 240,
    left: 0,
    top: 0,
    zIndex: 10,
    filter: 'drop-shadow(12px 8px 12px rgba(14, 15, 17, 0.05))',
  },
  subMenuList: {
    height: '100%',
    background: theme.palette.white,
    width: '100%',
    padding: 18,
    transition: 'all ease .3s',
    borderRadius: '0 16px 16px 0',
  },
  subMenuItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    padding: '14px 16px',
    borderRadius: 16,
    cursor: 'pointer',
    transition: 'all ease .3s',
    color: theme.colors.grey[10],
    '&:active, &:hover, &.current': {
      background: theme.colors.grey[95],
    },
  },
  menuText: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
}));
