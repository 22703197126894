export const USERS_PATH = '/users';
export const MEDIA_PATH = '/media';
export const GET_USERS_IMAGE_PATH = '/image';
export const IMAGE_BULK = `${MEDIA_PATH}/images/bulk`;
export const FILE_BULK = `${MEDIA_PATH}/documents/bulk`;
export const USER_PHOTO_UPLOAD = `${USERS_PATH}/photos`;
export const MEDIA_IMAGES_UPLOAD = `${MEDIA_PATH}/images/upload`;
export const MEDIA_DOCUMENTS_UPLOAD = `${MEDIA_PATH}/documents/upload`;
export const USER_ATTACHMENTS_UPLOAD = `${USERS_PATH}/attachments`;
export const SEND_AUDIO_RECORDING = 'https://ops.nurselink.io/api/speech/transcribe';
export const ACCESS_CONTROL_API = 'https://ops.nurselink.io/api/access-control/menu-access';
export const STATES_UNITED_STATES_API = 'https://countriesnow.space/api/v0.1/countries/states';
