import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  role: null,
  image: null,
  fullName: null,
  phoneNumber: null,
  companyName: null,
  professions: [],
};

export const authSlice = createSlice({
  name: 'base',
  initialState,
  reducers: {
    saveUserData: (state, action) => {
      state.id = action.payload.id;
      state.role = action.payload.role;
      state.image = action.payload.image;
      state.selectedProfessions = action.payload.professions;
      state.fullName = action.payload.fullname;
      state.phoneNumber = action.payload.phone_number;
      state.companyName = action.payload.company_name;
    },
  },
});

export const { saveUserData } = authSlice.actions;

export default authSlice.reducer;
