import { gql } from '@apollo/client';

export const getUserByToken = gql`
  query getUserByToken {
    getUserByToken {
      id
      company_name
      email
      fullname
      professions
      licenses
      phone_number
      role
      image {
        path
        filename
      }
    }
  }
`;

export const getProfessions = gql`
  query getProfessions {
    getProfessions {
      id
      name
      value
    }
  }
`;

export const getUserByEmails = gql`
  query getEmails {
    getEmails {
      value
      is_primary
      is_verified
      send_date
    }
  }
`;

export const checkUserByEmail = gql`
  query checkUserByEmail($input: CheckUserByEmail!) {
    checkUserByEmail(input: $input) {
      user_id
    }
  }
`;

export const getProfileSettingsStatuses = gql`
  query GetProfileSettingsStatuses {
    getProfileSettingsStatuses {
      background_check
      email
      phone
      photo_id
      professional_license
      speciality_section
      profile_photo
      tax_info
      mailing_address
    }
  }
`;

export const getPhoneNumbers = gql`
  query GetPhones {
    getPhones {
      is_primary
      is_verified
      send_date
      value
    }
  }
`;

export const verifyPhone = gql`
  mutation VerifyPhone($input: ContactVerifyInput!) {
    verifyPhone(input: $input) {
      result
    }
  }
`;

export const verifyEmail = gql`
  mutation verifyEmail($input: ContactVerifyInput!) {
    verifyEmail(input: $input) {
      account_id
      result
    }
  }
`;

export const setNewEmail = gql`
  mutation setNewEmail($input: SetNewEmail!) {
    setNewEmail(input: $input) {
      result
    }
  }
`;

export const setNewPhone = gql`
  mutation SetNewPhone($input: NewPhoneInput!) {
    setNewPhone(input: $input) {
      result
    }
  }
`;

export const setPrimaryEmail = gql`
  mutation SetEmailPrimary($input: ContactInput!) {
    setEmailPrimary(input: $input) {
      result
    }
  }
`;

export const setPrimaryPhone = gql`
  mutation SetPhonePrimary($input: PhoneInput!) {
    setPhonePrimary(input: $input) {
      result
    }
  }
`;

export const deleteEmail = gql`
  mutation RemoveEmail($input: ContactInput!) {
    removeEmail(input: $input) {
      result
    }
  }
`;

export const deletePhone = gql`
  mutation RemovePhone($input: PhoneInput!) {
    removePhone(input: $input) {
      result
    }
  }
`;

export const setTaxInfo = gql`
  mutation SetTaxInfo($input: TaxInput!) {
    setTaxInfo(input: $input) {
      result
    }
  }
`;

export const getUserLicenses = gql`
  query GetUserLicenses {
    getUserLicenses {
      date_created
      date_expired
      document_number
      id
      image_url
      is_verified
      license {
        id
        name
        value
      }
    }
  }
`;

export const removeUserLicense = gql`
  mutation RemoveUserLicense($removeUserLicenseId: Int!) {
    removeUserLicense(id: $removeUserLicenseId) {
      result
    }
  }
`;

export const getLicenses = gql`
  query Query {
    getLicenses
  }
`;

export const getLicenseCategories = gql`
  query Query($licenseId: Int!) {
    getLicenseCategories(license_id: $licenseId)
  }
`;

export const updateLicenseProfessions = gql`
  mutation UpdateLicenseProfessions($updatedUserProfessionsIds: [Int!]) {
    updateLicenseProfessions(ids: $updatedUserProfessionsIds) {
      result
    }
  }
`;

export const getTaxInfo = gql`
  query GetUserByToken {
    getUserByToken {
      tax_id
      tax {
        household_status
        filling_frequency
        currency
        amount
        id
        pay_count
        reminder_frequency
        reminder_time
        reminder_withold
        tax_date
        tax_id
      }
    }
  }
`;

export const getVerificationDetails = gql`
  query GetVerificationCheck {
    getVerificationCheck {
      id
      document_type
      identification_number
      expiration_date
      updated_at
      notify_before_expired
    }
  }
`;

export const setDocumentExpiryNotification = gql`
  mutation SetDocumentExpiryNotification($input: NotifyInput!) {
    setDocumentExpiryNotification(input: $input) {
      result
    }
  }
`;

export const sendSms = gql`
  mutation SendSMS($input: SMSInput) {
    sendSMS(input: $input) {
      result
    }
  }
`;

export const getUserData = gql`
  query GetUserData($id: Int!) {
    getUserData(id: $id) {
      locations {
        city
        house_number
        is_primary
        postal_code
        state
        street
      }
      status
      email
      phone_number
    }
  }
`;

export const getLocations = gql`
  query GetLocations {
    getLocations {
      id
      lat
      lng
      street
      city
      address_label
      house_number
      state
      postal_code
      is_primary
      is_billing_address
      direction
      radius
    }
  }
`;

export const updateLocations = gql`
  mutation UpdateLocations($updateLocationsId: Int!, $input: LocationInputType!) {
    updateLocations(id: $updateLocationsId, input: $input) {
      result
    }
  }
`;

export const updateLocationsBulk = gql`
  mutation UpdateBulkLocations($input: [LocationAddressInputType]) {
    updateBulkLocations(input: $input) {
      result
    }
  }
`;

export const createLocation = gql`
  mutation CreateLocations($input: Address!) {
    createLocations(input: $input) {
      result
    }
  }
`;

export const removeLocation = gql`
  mutation RemoveLocation($removeLocationId: Int!) {
    removeLocation(id: $removeLocationId) {
      result
    }
  }
`;
