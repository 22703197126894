import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: '256px minmax(0, 1fr)',
    width: '100%',
  },
  sidebar: {
    minHeight: '100vh',
    width: '100%',
    maxWidth: 256,
    zIndex: 5,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 32,
  },
  header: {
    width: '100%',
    position: 'sticky',
    top: 0,
    zIndex: 4,
  },
  content: {
    width: '100%',
  },
});
