import uuid from './uuid';
import dayjs from './dayjs';
import isEmpty from './isEmpty';
import isEqual from './isEqual';
import uploadFiles from './uploadFiles';
import isImageFile from './isImageFile';
import getRouteName from './getRouteName';
import withSuspense from './withSuspense';
import { setCookie, getCookie, eraseCookie } from './cookie';
import { isMobile, isIOS, isAndroid, isWindowsPhone } from './isMobile';
import { getFromStorage, removeFromStorage, saveToStorage } from './persist';
import {
  shortenEmail,
  maskPhoneNumber,
  secondsToMinutes,
  formatPhoneNumber,
  getPasswordStrength,
  kilobytesToMegabytes,
} from './string';
import { downloadFile } from './downloadFile';
import { convertBlobToWav } from './audio';

export {
  uuid,
  dayjs,
  isIOS,
  isEqual,
  isEmpty,
  isMobile,
  setCookie,
  getCookie,
  isAndroid,
  uploadFiles,
  eraseCookie,
  isImageFile,
  getRouteName,
  withSuspense,
  saveToStorage,
  getFromStorage,
  isWindowsPhone,
  removeFromStorage,
  shortenEmail,
  maskPhoneNumber,
  getPasswordStrength,
  formatPhoneNumber,
  kilobytesToMegabytes,
  secondsToMinutes,
  downloadFile,
  convertBlobToWav,
};
