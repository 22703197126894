import { useCallback, useRef, createContext, useMemo } from 'react';
import io from 'socket.io-client';
import { jwtDecode } from 'jwt-decode';
import { useDispatch } from 'react-redux';

import { useMount } from 'utils/hooks';
import { getCookie } from 'utils/cookie';
import { actions } from 'store/reducers/chat';

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const dispatch = useDispatch();
  const socketRef = useRef(null);
  const eventQueueRef = useRef([]);
  const eventListeners = useRef({});

  const emitEvent = useCallback(async (eventName, data, callback, delay) => {
    if (socketRef.current && socketRef.current.connected) {
      await socketRef.current.emit(eventName, data);
      callback && setTimeout(() => callback(), delay || 500);
    } else {
      eventQueueRef.current.push({ eventName, data, callback, delay });
    }
  }, []);

  const listenEvent = useCallback((eventName, callback) => {
    if (!eventListeners.current[eventName]) {
      eventListeners.current[eventName] = [];
    }

    eventListeners.current[eventName].push(callback);

    return () => {
      eventListeners.current[eventName] = eventListeners.current[eventName].filter(listener => listener !== callback);
    };
  }, []);

  useMount(() => {
    const token = getCookie('accessToken');

    const { userId } = jwtDecode(token);

    socketRef.current = io(process.env.REACT_APP_WEB_SOCKET_URL, {
      path: '/socket.io/',
      query: { token: token, user_id: userId },
      withCredentials: true,
      transports: ['websocket'],
    });

    socketRef.current.on('connect', () => {
      console.log(
        '%c[open] Connection established',
        'background: #079992; color: #ffffff; padding: 3px 5px; border-radius: 3px',
      );

      eventQueueRef.current.forEach(({ eventName, data, callback, delay }) => {
        socketRef.current.emit(eventName, data);
        callback && setTimeout(() => callback(), delay || 1000);
      });

      eventQueueRef.current = [];
    });

    socketRef.current.on('pulse:socket', ({ data, type } = {}) => {
      dispatch(actions[type]?.(data) || { type: 'error' });

      if (eventListeners.current[type]) {
        eventListeners.current[type].forEach(callback => callback(data));
      }
    });

    socketRef.current.on('disconnect', () => {
      console.log(
        '%c[close] Connection died',
        'background: #c0392b; color: #ffffff; padding: 3px 5px; border-radius: 3px',
      );
    });

    return () => socketRef.current.disconnect();
  });

  const value = useMemo(
    () => ({
      emitEvent,
      listenEvent,
      socket: socketRef.current,
    }),
    [emitEvent, listenEvent],
  );

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};
