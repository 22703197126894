import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(theme => ({
  root: {},
  dropdownTrigger: {
    cursor: 'pointer',
    position: 'relative',
  },
  dropdownContent: {
    marginTop: 12,
    width: 276,
    background: theme.palette.white,
    borderRadius: 20,
    padding: '10px 0',
    boxShadow: '0px 8px 12px 0px rgba(14, 15, 17, 0.10)',
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    zIndex: 5,
  },
  optionWrapper: {
    padding: '0 10px',
  },
  optionItem: {
    display: 'flex',
    alignItems: 'center',
    padding: 12,
    gap: 12,
    borderRadius: 16,
    cursor: 'pointer',
    transition: 'all ease .3s',
    color: theme.colors.grey[10],
    '&:active, &:hover, &.active': {
      background: theme.colors.grey[95],
    },
  },
  optionIcon: {
    flexShrink: 0,
  },
  separator: {
    background: '#EBEFF2',
    height: 1,
    width: '100%',
    margin: '8px 0',
  },
  arrowWrapper: {
    width: 16,
    height: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 999,
    border: `1px solid ${theme.palette.white}`,
    background: theme.colors.grey[80],
    position: 'absolute',
    bottom: 0,
    right: 0,
    transition: 'all ease .3s',
    transform: 'rotate(180deg)',
  },
  arrow: {},
  opened: {
    transform: 'rotate(0deg)',
  },
  wrapper: {
    position: 'relative',
    '&.active, &:hover': {
      '& .avatar:after': {
        content: '""',
        position: 'absolute',
        top: -1,
        left: -1,
        width: 'calc(100% - 2px)',
        height: 'calc(100% - 2px)',
        borderRadius: 999,
        border: `2px solid ${theme.colors.blue[80]}`,
      },
    },
  },
}));
