import { useState, useEffect } from 'react';
import dayjs from 'dayjs';

const useTimeCounter = startTime => {
  const [timeDetails, setTimeDetails] = useState({
    formattedTime: '',
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  useEffect(() => {
    const updateDifference = () => {
      const now = dayjs();
      const start = dayjs(startTime);
      const duration = now.diff(start, 'second');

      // Format the time difference
      const updatedTime = dayjs().startOf('day').add(duration, 'second');

      const formattedHours = String(updatedTime.hour()).padStart(2, '0');
      const formattedMinutes = String(updatedTime.minute()).padStart(2, '0');
      const formattedSeconds = String(updatedTime.second()).padStart(2, '0');

      setTimeDetails({
        formattedTime: `${formattedHours}:${formattedMinutes}:${formattedSeconds}`,
        hours: formattedHours,
        minutes: formattedMinutes,
        seconds: formattedSeconds,
      });
    };

    const interval = setInterval(updateDifference, 1000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [startTime]);

  return timeDetails;
};

export default useTimeCounter;
