import { lazy, memo, useEffect, useRef, useState } from 'react';

import { isMobile, withSuspense } from 'utils';
import deviceName from 'utils/deviceName';
import { useTranslator } from 'utils/translator';
import MobileLogo from 'sections/layout/header/mobileLogo';
import SearchInput from 'components/search';
import { ReactComponent as NotificationIcon } from 'assets/icons/Notification.svg';

import NotificationButton from './NotificationButton';
import { useStyles } from './styles';

const ProfileMenu = withSuspense(lazy(() => import(`./ProfileMenu/${deviceName}`)));

const isMacOS = () => {
  if (navigator.userAgentData) {
    return navigator.userAgentData.platform === 'macOS';
  }
  return /Mac/i.test(navigator.userAgent);
};

const Header = () => {
  const inputRef = useRef(null);
  const { t } = useTranslator();
  const [search, setSearch] = useState('');
  const { root, headerLeft, headerRight } = useStyles();

  useEffect(() => {
    if (inputRef.current) {
      const handleKeyDown = event => {
        const isMac = isMacOS();
        const isSearchShortcut =
          (isMac && event.metaKey && event.key === 'f') || (!isMac && event.ctrlKey && event.key === 'f');

        if (isSearchShortcut) {
          event.preventDefault();
          inputRef.current.focus();
        }

        if (event.key === 'Escape') {
          inputRef.current.blur();
          setSearch('');
        }
      };

      window.addEventListener('keydown', handleKeyDown);
      return () => window.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  return (
    <>
      <div className={root}>
        {isMobile && <MobileLogo />}
        <div className={headerLeft}>
          <SearchInput
            showHotkey
            ref={inputRef}
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder={t('search_doctors_services_placeholder')}
          />
        </div>
        <div className={headerRight}>
          <NotificationButton Icon={NotificationIcon} withBadge />
          <ProfileMenu />
        </div>
      </div>
    </>
  );
};

export default memo(Header);
