import { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { NavLink } from 'react-router-dom';

import { eraseCookie } from 'utils';
import { useTranslator } from 'utils/translator';
import { HELP_CENTER, PROFILE, SETTINGS, SIGN_IN_PATH, SMART_CONNECT } from 'constants/path';
import { getUserByToken } from 'connection/graphql/user';
import Avatar from 'components/avatar';
import Dropdown from 'components/dropdown';
import Typography from 'components/typography';
import DropdownOption from 'components/dropdown/DropdownOption';
import DropdownSeparator from 'components/dropdown/DropdownSeperator';
import { ReactComponent as StarIcon } from 'assets/icons/Star.svg';
import { ReactComponent as LogOutIcon } from 'assets/icons/Log Out.svg';
import { ReactComponent as HelpIcon } from 'assets/icons/Help Center.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/Settings.svg';

import { useStyles } from './styles';

const ProfileMenu = () => {
  const { t } = useTranslator();

  const { data: { getUserByToken: { fullname, image } = {} } = {} } = useQuery(getUserByToken);

  const { headerRightAvatar, profileWrapper, profileAvatarWrapper, textWrapper, rate, rateIcon } = useStyles();

  const onLogOut = useCallback(() => {
    eraseCookie('accessToken');
    eraseCookie('refreshToken');
    window.location.href = SIGN_IN_PATH;
  }, []);

  return (
    <Dropdown trigger={<Avatar img={image?.path} className={headerRightAvatar} size={40} />} withArrow>
      <div className={profileWrapper}>
        <div className={profileAvatarWrapper}>
          <Avatar img={image?.path} />
        </div>
        <div>
          <Typography variant="bodyL" bold>
            {fullname}
          </Typography>
          <div className={textWrapper}>
            <StarIcon className={rateIcon} />
            <Typography variant="bodyM" className={rate}>
              4.98
            </Typography>
          </div>
        </div>
      </div>
      <DropdownSeparator />
      <NavLink to={`${SETTINGS}${PROFILE}`}>
        {({ isActive }) => <DropdownOption label={t('settings')} Icon={SettingsIcon} isActive={isActive} />}
      </NavLink>
      <NavLink to={`${SMART_CONNECT}${HELP_CENTER}`}>
        {({ isActive }) => <DropdownOption label={t('help_center')} Icon={HelpIcon} isActive={isActive} />}
      </NavLink>
      <DropdownSeparator />
      <DropdownOption onClick={onLogOut} label={t('log_out')} Icon={LogOutIcon} />
    </Dropdown>
  );
};

export default ProfileMenu;
