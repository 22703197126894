import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

import { ACCESS_CONTROL_API } from 'api';
import { getCookie, saveToStorage } from 'utils';

const initialState = {
  lang: 'en',
  accessControl: {
    loading: false,
    settings: [],
  },
};

export const getAccessControlSetting = createAsyncThunk('base/accessControl', async (_, { rejectWithValue }) => {
  try {
    const token = getCookie('accessToken');
    const { userId } = jwtDecode(token);

    const { data } = await axios.post(
      ACCESS_CONTROL_API,
      { user_id: userId },
      { headers: { Authorization: `Bearer ${token}` } },
    );

    return data;
    // eslint-disable-next-line no-unreachable
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const baseSlice = createSlice({
  name: 'base',
  initialState,
  reducers: {
    setCurrentLanguage: (state, action) => {
      saveToStorage('lang', action.payload);
      state.lang = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getAccessControlSetting.fulfilled, (state, action) => {
      state.accessControl.loading = false;
      state.accessControl.settings = action.payload;
    });
    builder.addCase(getAccessControlSetting.rejected, (state, action) => {
      state.accessControl.loading = false;
    });
    builder.addCase(getAccessControlSetting.pending, state => {
      state.accessControl.loading = true;
    });
  },
});

export const { setCurrentLanguage } = baseSlice.actions;

export default baseSlice.reducer;
