import { memo } from 'react';
import clsx from 'clsx';

import Typography from 'components/typography';
import { ReactComponent as SearchIcon } from 'assets/icons/Search.svg';
import { ReactComponent as ClearIcon } from 'assets/icons/Clear.svg';

import { useStyles } from './styles';

const SearchInput = ({ className, value, showHotkey, allowClear, onClear, ...rest }) => {
  const { root, input, searchIcon, hotKey, active, clear } = useStyles();

  return (
    <label className={clsx(className, root)}>
      <SearchIcon className={searchIcon} />
      <input type="text" value={value} className={clsx(input, { [active]: value, allowClear })} {...rest} />
      {showHotkey && (
        <div className={hotKey}>
          <Typography variant="labelL" bold>
            {value ? 'Esc' : 'F'}
          </Typography>
        </div>
      )}
      {allowClear && value && <ClearIcon className={clear} onClick={onClear} />}
    </label>
  );
};

export default memo(SearchInput);
