import { Link } from 'react-router-dom';

import { navigation } from 'config';
import { DASHBOARD } from 'constants/path';
import { ReactComponent as NurselinkLogo } from 'assets/images/NurselinkLogo.svg';
import { useMemoSelector } from 'utils/hooks';
import { getAccessControl } from 'store/selectors';

import MenuItem from './MenuItem';
import { useStyles } from './styles';

const Sidebar = () => {
  const { settings, loading } = useMemoSelector(getAccessControl);
  const { root, sidebarLogo, sidebarContent, sidebarGroup } = useStyles();

  return (
    <div className={root}>
      <div className={sidebarLogo}>
        <Link to={DASHBOARD}>
          <NurselinkLogo />
        </Link>
      </div>
      <div className={sidebarContent}>
        {!loading &&
          navigation.map(group => {
            const route = group.map(route => {
              const setting = settings.find(({ key }) => key === route.name);

              if (route.subMenus && setting) {
                route.subMenus = route.subMenus
                  .map(menu => ({
                    ...menu,
                    href: `${route.href}${menu.href}`,
                    setting: setting.sub_menu.find(({ key }) => menu.label === key),
                  }))
                  .filter(({ setting }) => !setting || setting.status === 1);
              }

              return { ...route, setting };
            });

            return (
              <div className={sidebarGroup} key={route[0].label}>
                {route
                  .filter(({ setting }) => !setting || setting.status === 1)
                  .map(menu => (
                    <MenuItem key={menu.label} {...menu} />
                  ))}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Sidebar;
