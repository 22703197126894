import { gql } from '@apollo/client';

export const getSchedulesByDate = gql`
  query GetSchedulesByDate($input: GetSchedulesByDateInput) {
    getSchedulesByDate(input: $input) {
      totalCount
      schedules {
        address {
          city
          coordinates
          house_number
          state
          street
        }
        tasks {
          status
          description
          id
        }
        id
        medicean {
          coordinates
          fullname
          id
          image
          personnel
        }
        notes
        patient {
          coordinates
          fullname
          id
          image
        }
        profession
        reference_id
        start_date
        status
        supervised_by {
          coordinates
          fullname
          id
          image
        }
        transcriptions_count
      }
    }
  }
`;

export const getSchedule = gql`
  query GetSchedule($scheduleId: Int) {
    getSchedule(schedule_id: $scheduleId) {
      id
      patient_id
      doctor_id
      profession_id
      start_date
      supervisor_id
      status
      notes
    }
  }
`;

export const getFullSchedule = gql`
  query GetSchedule($schedule_id: Int!) {
    getSchedule(schedule_id: $schedule_id) {
      address {
        city
        coordinates
        house_number
        state
        street
      }
      tasks {
        status
        description
        id
      }
      id
      medicean {
        coordinates
        fullname
        id
        image
        personnel
      }
      notes
      patient {
        coordinates
        fullname
        id
        image
      }
      profession
      reference_id
      start_date
      status
      supervised_by {
        coordinates
        fullname
        id
        image
      }
      transcriptions_count
    }
  }
`;

export const getDoctorsByPatientLocation = gql`
  query GetDoctorsByPatientLocation($input: GetDoctorsByPatientLocationInput) {
    getDoctorsByPatientLocation(input: $input) {
      coordinates
      medicean {
        id
        fullname
        image
        coordinates
      }
    }
  }
`;

export const getMyLocation = gql`
  query GetMyLocation {
    getMyLocation {
      coordinates
    }
  }
`;

export const getScheduleGroupCounts = gql`
  query GetScheduleGroupCounts($input: ScheduleGroupCountInput!) {
    getScheduleGroupCounts(input: $input) {
      completed
      pending
      progress
    }
  }
`;

export const getScheduleGroupDaysByYearAndMonth = gql`
  query GetScheduleGroupDaysByYearAndMonth($input: ScheduleDatesInput!) {
    getScheduleGroupDaysByYearAndMonth(input: $input) {
      schedule_day
    }
  }
`;

export const getUsersByClinicId = gql`
  query GetUsersByClinicId($clinicId: Int, $role: Role) {
    getUsersByClinicId(clinic_id: $clinicId, role: $role) {
      id
      coordinates
      fullname
      role
    }
  }
`;

export const getCategoriesWithProfessions = gql`
  query Query {
    getCategoriesWithProfessions
  }
`;

export const getGroupedRatings = gql`
  query GetGroupedRatings($personnelType: PersonnelType) {
    getGroupedRatings(personnel_type: $personnelType) {
      count
      rating
    }
  }
`;

export const getNearByDoctors = gql`
  query GetNearByDoctors($input: GetNearByDoctorsInput) {
    getNearByDoctors(input: $input) {
      average_rating
      distance
      medicean {
        coordinates
        fullname
        id
        image
      }
      is_favorite
      reviews_count
    }
  }
`;

export const createSchedule = gql`
  mutation CreateSchedule($input: ScheduleInput!) {
    createSchedule(input: $input) {
      result
    }
  }
`;

export const getSchedulesByStatuses = gql`
  query GetSchedulesByStatuses($input: GetSchedulesByStatusesInput) {
    getSchedulesByStatuses(input: $input) {
      schedules {
        id
        patient {
          coordinates
          fullname
          id
          image
        }
        medicean {
          coordinates
          fullname
          id
          image
          personnel
        }
        profession
        start_date
        transcriptions_count
        supervised_by {
          coordinates
          fullname
          id
          image
        }
        reference_id
        status
        address {
          city
          coordinates
          house_number
          state
          street
        }
        notes
        tasks {
          status
          description
          id
        }
      }
    }
  }
`;
