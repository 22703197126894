import { ReactComponent as SpinnerIcon } from 'assets/icons/Spinner.svg';
import { useStyles } from 'components/loader/styles';

const Spinner = ({ size = 40 }) => {
  const { spinner } = useStyles({ size });
  // eslint-disable-next-line no-debugger

  return <SpinnerIcon className={spinner} />;
};

export default Spinner;
