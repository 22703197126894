const XXXL = 'xxxl';
const XXL = 'xxl';
const XL = 'xl';
const L = 'l';
const M = 'm';
const S = 's';
const XS = 'xs';
const XXS = 'xxs';

const IconL = 'L';
const IconM = 'M';
const IconS = 'S';

export const typographySizes = {
  L: 'labelXL',
  M: 'labelXL',
  S: 'labelL',
  XS: 'labelM',
};

export const buttonSizes = {
  L: {
    x: XXXL,
    y: L,
    icon: XL,
    height: '52px',
    iconSize: IconL,
  },
  M: {
    x: XXL,
    y: M,
    icon: M,
    height: '48px',
    iconSize: IconL,
  },
  S: {
    x: L,
    y: XS,
    icon: S,
    height: '40px',
    iconSize: IconM,
  },
  XS: {
    x: M,
    y: XS,
    icon: XS,
    height: '32px',
    iconSize: IconS,
  },
};

export const inputSizes = {
  L: {
    height: '56px',
    x: XXL,
    y: XL,
  },
  M: {
    height: '48px',
    x: XL,
    y: M,
  },
  S: {
    height: '40px',
    x: L,
    y: XS,
  },
};

export const selectSizes = {
  L: {
    height: '56px',
    x: XXL,
    y: M,
    gap: XS,
  },
  M: {
    height: '48px',
    x: XL,
    y: M,
    gap: XS,
  },
  S: {
    height: '40px',
    x: M,
    y: XS,
    gap: XXS,
  },
};

export const collapsibleSizes = {
  L: {
    height: 56,
    x: XL,
    y: M,
    radius: 16,
    arrow: 24,
  },
  S: {
    height: 48,
    x: M,
    y: S,
    radius: 12,
    arrow: 20,
  },
};

export const TypographyVariants = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  headlineL: 'p',
  headlineM: 'p',
  headlineS: 'p',
  titleXL: 'p',
  titleL: 'p',
  titleM: 'p',
  titleS: 'p',
  bodyXXL: 'div',
  bodyXL: 'div',
  bodyL: 'div',
  bodyM: 'span',
  bodyS: 'span',
  labelXXL: 'label',
  labelXL: 'label',
  labelL: 'label',
  labelM: 'label',
  labelS: 'label',
};

export const codeInputSizes = {
  L: {
    width: 56,
    height: 64,
  },
  M: {
    width: 48,
    height: 56,
  },
  S: {
    width: 40,
    height: 48,
  },
};

export const progressSizes = {
  L: 520,
  S: 328,
};

export const accountTypeSizes = {
  L: {
    width: 520,
  },
  S: {
    width: 366,
  },
};

export const chipSizes = {
  L: {
    x: XL,
    y: XS,
    icon: S,
    height: '39px',
    typography: 'labelXL',
  },
  M: {
    x: XL,
    y: XS,
    icon: S,
    height: '36px',
    typography: 'labelL',
  },
  S: {
    x: M,
    y: XXS,
    icon: XXS,
    height: '28px',
    typography: 'labelM',
  },
};

export const badgeTextSizes = {
  64: 'labelXL',
  56: 'labelXL',
  44: 'labelXL',
  40: 'labelXL',
  36: 'labelXL',
  32: 'labelL',
  24: 'labelS',
};

export const tabSizes = {
  M: {
    x: L,
    y: M,
    typography: 'bodyL',
  },
  L: {
    x: XL,
    y: L,
    typography: 'bodyXL',
  },
  S: {
    x: S,
    y: XS,
    typography: 'bodyM',
  },
};
