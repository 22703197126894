import { ThemeProvider, createUseStyles } from 'react-jss';

import { colors } from './colors';

export const theme = {
  colors,
  palette: {
    black: colors.grey[0],
    white: colors.grey[100],
    main: colors.grey[10],
    primary: colors.blue[40],
    secondary: colors.violet[40],
    success: colors.green[40],
    error: colors.red[40],
    tertiary: colors.yellow[40],
    neutral: colors.grey[40],
  },
  background: {
    primary: {
      main: colors.blue[40],
      light: colors.blue[50],
      text: colors.grey[100],
      hover: colors.blue[90],
      tonalLight: colors.blue[90],
      tonalDark: colors.blue[70],
      tonalText: colors.blue[40],
      outlinedBorder: colors.blue[40],
      outlinedText: colors.blue[40],
      outlinedColor: colors.blue[50],
      outlinedTextHover: colors.grey[100],
      progressMain: colors.blue[40],
      progressSecondary: colors.blue[80],
    },
    secondary: {
      main: colors.violet[40],
      light: colors.violet[50],
      text: colors.grey[100],
      hover: colors.violet[90],
      tonalLight: colors.violet[95],
      tonalDark: colors.violet[70],
      tonalText: colors.grey[100],
      outlinedBorder: colors.violet[40],
      outlinedText: colors.violet[40],
      outlinedColor: colors.violet[50],
      outlinedTextHover: colors.grey[100],
      progressMain: colors.violet[40],
      progressSecondary: colors.violet[80],
    },
    warning: {
      main: colors.yellow[40],
      light: colors.yellow[50],
      text: colors.grey[10],
      hover: colors.yellow[90],
      tonalLight: colors.yellow[95],
      tonalDark: colors.yellow[70],
      tonalText: colors.grey[10],
      outlinedBorder: colors.yellow[40],
      outlinedText: colors.yellow[40],
      outlinedColor: colors.yellow[50],
      outlinedTextHover: colors.grey[100],
      progressMain: colors.yellow[40],
      progressSecondary: colors.yellow[80],
    },
    success: {
      main: colors.green[40],
      light: colors.green[50],
      text: colors.grey[100],
      hover: colors.green[90],
      tonalLight: colors.green[95],
      tonalDark: colors.green[70],
      tonalText: colors.grey[100],
      outlinedBorder: colors.green[40],
      outlinedText: colors.green[40],
      outlinedColor: colors.green[50],
      outlinedTextHover: colors.grey[100],
      progressMain: colors.green[40],
      progressSecondary: colors.green[80],
    },
    error: {
      main: colors.red[40],
      light: colors.red[50],
      text: colors.grey[100],
      hover: colors.red[90],
      tonalLight: colors.red[95],
      tonalDark: colors.red[70],
      tonalText: colors.grey[100],
      outlinedBorder: colors.red[40],
      outlinedText: colors.red[40],
      outlinedColor: colors.red[50],
      outlinedTextHover: colors.grey[100],
      progressMain: colors.red[40],
      progressSecondary: colors.red[80],
    },
    neutral: {
      main: colors.grey[40],
      light: colors.grey[50],
      text: colors.grey[10],
      hover: colors.grey[90],
      tonalLight: colors.grey[95],
      tonalDark: colors.grey[80],
      tonalText: colors.grey[10],
      outlinedBorder: colors.grey[50],
      outlinedText: colors.grey[10],
      outlinedColor: colors.blue[50],
      outlinedTextHover: colors.grey[100],
      progressMain: colors.grey[40],
      progressSecondary: colors.grey[80],
    },
    disabled: {
      main: colors.grey[80],
      light: colors.grey[90],
      text: colors.grey[10],
    },
  },
  chip: {
    neutral: {
      tonal: colors.grey[95],
      tonalText: colors.grey[10],
      tonalHover: colors.blue[50],
      tonalTextHover: colors.grey[100],
      outlinedBorder: colors.grey[50],
      outlinedText: colors.grey[40],
      outlinedHover: colors.blue[50],
      outlinedTextHover: colors.grey[100],
    },
    primary: {
      tonal: colors.blue[90],
      tonalText: colors.blue[50],
      tonalHover: colors.blue[80],
      tonalTextHover: colors.blue[40],
      outlinedBorder: colors.blue[90],
      outlinedText: colors.blue[50],
      outlinedHover: colors.blue[90],
      outlinedTextHover: colors.blue[40],
    },
    secondary: {
      tonal: colors.violet[90],
      tonalText: colors.violet[50],
      tonalHover: colors.violet[80],
      tonalTextHover: colors.violet[40],
      outlinedBorder: colors.violet[90],
      outlinedText: colors.violet[50],
      outlinedHover: colors.violet[90],
      outlinedTextHover: colors.violet[40],
    },
  },
  borderRadius: {
    l: '8px',
    m: '6px',
  },
  spacing: {
    xxxl: '24px',
    xxl: '20px',
    xl: '16px',
    l: '14px',
    m: '12px',
    s: '10px',
    xs: '8px',
    xxs: '6px',
  },
  icon: {
    XL: '28px',
    L: '24px',
    M: '20px',
    S: '16px',
  },
  iconLarge: {
    XL: '64px',
    L: '56px',
    M: '48px',
    S: '40px',
  },
  maxWidth: {
    button: {
      L: 432,
      M: 368,
      S: 288,
      XS: 256,
    },
    input: {
      L: 432,
      M: 368,
      S: 320,
    },
  },
  tooltip: {
    backgroundColor: '#16171F',
    textColor: colors.grey[100],
  },
  screen: {
    sm: '@media (max-width: 640px)',
    md: '@media (max-width: 768px)',
    lg: '@media (max-width: 1024px)',
    xl: '@media (max-width: 1280px)',
    xxl: '@media (max-width: 1600px)',
  },
};

const useGlobalStyles = createUseStyles({
  '@global': {
    body: {
      color: theme.palette.main,
    },
    '*': {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box',
    },
    '::-webkit-scrollbar': {
      width: 4,
      height: 4,
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: theme.colors.grey[90],
      borderRadius: 999,
      WebkitBorderRadius: 999,
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: theme.colors.blue[50],
      borderRadius: 999,
      WebkitBorderRadius: 999,
    },
  },
});

export const ThemeWrapper = ({ children }) => {
  useGlobalStyles();
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
