import { createUseStyles } from 'react-jss';

import { desktopStyles, mobileStyles } from 'assets/styles/helpers';

export const useStyles = createUseStyles(
  theme => ({
    root: {
      position: 'fixed',
      ...desktopStyles({ width: 426 }),
      ...mobileStyles({ width: '80%' }),
      height: '100vh',
      zIndex: 9999,
      right: 0,
      top: 0,
      transform: 'translateX(100%)',
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      paddingTop: 28,
    },
    toast: {
      display: 'flex',
      gap: 8,
      alignItems: 'center',
      padding: 12,
      position: 'relative',
      zIndex: 9999,
      borderRadius: 8,
      boxShadow: '0px 4px 12px 0px rgba(78, 113, 255, 0.30)',
      transform: 'translateX(0px)',
      transition: 'transform ease-in .3s',
      '&.visible': {
        transform: 'translateX(-110%)',
        transition: 'transform ease-in-out .3s',
      },
      '&.success': {
        background: theme.palette.primary,
        color: theme.palette.white,
      },
      '&.error': {
        background: theme.palette.error,
        color: theme.palette.white,
      },
    },
  }),
  { name: 'Toast' },
);
