import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeTab: 'chat',
  userSidebar: false,
  layoutMode: 'light',
  activeUser: null,
  users: [],
  groups: [],
  contacts: [],
  userImages: {},
  isChatLoading: true,
  lastDeletedChat: {},
  isMessagesLoading: true,
};

function changeBodyAttribute(attribute, value) {
  if (document.body) document.body.setAttribute(attribute, value);
  return true;
}

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setActiveUser: (state, action) => {
      state.isMessagesLoading = true;
      state.activeUser = action.payload;
    },
    setDeletedChat: (state, action) => {
      state.lastDeletedChat = action.payload;
    },
    setUserAvatar: (state, action) => {
      state.userImages[action.payload.id] = action.payload.image;
    },
    getMessages: (state, action) => {
      state.isMessagesLoading = false;
      state.messages = action.payload;
    },
    getChats: (state, action) => {
      const isChatAlreadyDeleted = state.lastDeletedChat.id === state.activeUser;
      const isActiveChatDeleted = ![...action.payload, ...state.groups].some(({ _id }) => _id === state.activeUser);

      if (!isChatAlreadyDeleted) {
        if (state.activeUser && isActiveChatDeleted) {
          const selectedUserName = state.users.find(({ _id }) => _id === state.activeUser)?.name;

          state.lastDeletedChat.id = state.activeUser;
          state.lastDeletedChat.name = selectedUserName;
        } else if (!state.activeUser) {
          state.activeUser = action.payload[0]?._id;
        }
      }

      state.isChatLoading = false;
      state.users = action.payload;
    },
    receiveMessage: (state, action) => {
      state.messages = [...state.messages, action.payload];
    },
    getRoomByContact: (state, action) => {
      state.isMessagesLoading = true;

      if (action.payload.chat !== null) {
        state.users = [action.payload.chat, ...state.users];
      }
      state.activeUser = action.payload.room_id;
    },
    getContacts: (state, action) => {
      state.contacts = action.payload || [];
    },
    appendMessage: (state, action) => {
      state.messages = [...state.messages, { ...action.payload, userType: 'sender', notSent: true }];
    },
    getGroups: (state, action) => {
      const isChatAlreadyDeleted = state.lastDeletedChat.id === state.activeUser;
      const isActiveChatDeleted = ![...action.payload, ...state.users].some(({ _id }) => _id === state.activeUser);

      if (!isChatAlreadyDeleted) {
        if (state.activeUser && isActiveChatDeleted) {
          const selectedUserName = state.groups.find(({ _id }) => _id === state.activeUser).name;

          state.lastDeletedChat.isGroup = true;
          state.lastDeletedChat.id = state.activeUser;
          state.lastDeletedChat.name = selectedUserName;
        }
      }

      state.groups = action.payload;
    },
    createGroupChat: (state, action) => {
      state.groups = [action.payload, ...state.groups];
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    openUserSidebar: state => {
      state.userSidebar = true;
    },
    closeUserSidebar: state => {
      state.userSidebar = false;
    },
    changeLayoutMode: (state, action) => {
      const layoutMode = action.payload;

      changeBodyAttribute('data-bs-theme', layoutMode);
      localStorage.setItem('layoutMode', layoutMode);

      state.layoutMode = action.payload;
    },
  },
});

export const {
  createGroup,
  setActiveTab,
  appendMessage,
  setActiveUser,
  setUserAvatar,
  receiveMessage,
  setDeletedChat,
  openUserSidebar,
  closeUserSidebar,
  changeLayoutMode,
} = chatSlice.actions;

const { actions: sliceActions } = chatSlice;

export const actions = { ...sliceActions };

export default chatSlice.reducer;
