import { createUseStyles } from 'react-jss';

import { generateStyles } from 'assets/styles/helpers';

export const useStyles = createUseStyles(theme => ({
  root: {
    position: 'relative',
    display: 'inline-block',
    color: theme.colors.grey[40],
    width: '100%',
  },
  input: {
    height: 40,
    outline: 'none',
    padding: '8px 46px 8px 44px',
    ...generateStyles(16, 24),
    color: theme.colors.grey[40],
    background: theme.colors.grey[95],
    width: '100%',
    borderRadius: 8,
    border: 'none',
    boxShadow: `inset 0 0 0 1px ${theme.colors.grey[70]}`,
    transition: 'box-shadow 0.3s ease',
    '&:hover': {
      boxShadow: `inset 0 0 1px 1px ${theme.colors.grey[50]}`,
    },
    '&:focus': {
      outline: `2px solid ${theme.palette.primary}`,
      boxShadow: `0 0 1px 4px ${theme.colors.grey[80]}`,
      background: theme.palette.white,
    },
    '&:disabled': {
      borderColor: theme.colors.blue[90],
      background: theme.colors.blue[90],
      outline: 'none',
      boxShadow: 'none',
    },
    '&::placeholder': {
      ...generateStyles(16, 24),
      color: theme.colors.grey[40],
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '&$active': {
      outline: `2px solid ${theme.palette.primary}`,
      boxShadow: `0 0 1px 4px ${theme.colors.grey[80]}`,
      background: theme.palette.white,
    },
    '&.allowClear': {
      background: theme.palette.white,
    },
  },
  searchIcon: {
    position: 'absolute',
    left: 10,
    top: 8,
  },
  hotKey: {
    width: 28,
    height: 28,
    borderRadius: 6,
    background: '#EBEFF2',
    position: 'absolute',
    right: 6,
    top: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {},
  clear: {
    position: 'absolute',
    right: 10,
    top: 10,
    cursor: 'pointer',
    width: 20,
    height: 20,
  },
}));
