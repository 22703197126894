import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { Routes, Route } from 'react-router-dom';

import { routes } from 'router';
import Layout from 'sections/layout';
import Loader from 'components/loader';
import { useMemoSelector, useMount } from 'utils/hooks';
import { getCookie } from 'utils';
import { getUserByToken } from 'connection/graphql/user';
import { SIGN_IN_PATH } from 'constants/path';
import { ADMIN } from 'constants/main';
import { saveUserData } from 'store/reducers/auth';
import { ToastBar } from 'components/toast';
import { getAccessControl } from 'store/selectors';
import { getAccessControlSetting } from 'store/reducers/base';
import './index.css';

function App() {
  const dispatch = useDispatch();
  const { loading: accessControlLoading, settings } = useMemoSelector(getAccessControl);

  const { data, loading } = useQuery(getUserByToken);

  useMount(() => {
    if (process.env.NODE_ENV === 'production') {
      Object.keys(console).forEach(method => {
        if (method !== 'log') {
          // eslint-disable-next-line no-console
          console[method] = function noop() {};
        }
      });
    }
  });

  useEffect(() => {
    if (data?.getUserByToken.role === ADMIN) {
      window.location.href = process.env.REACT_APP_OPS_URI;
    }

    if (data?.getUserByToken) {
      dispatch(saveUserData(data?.getUserByToken));
    }
  }, [data?.getUserByToken, dispatch]);

  useMount(() => {
    dispatch(getAccessControlSetting());
  });

  useMount(() => {
    const accessToken = getCookie('accessToken');
    if (!accessToken) {
      window.location.href = SIGN_IN_PATH;
    }
  });

  if (loading || accessControlLoading) {
    return <Loader overlay />;
  }

  return (
    <div className="root">
      <main>
        <Routes>
          <Route element={<Layout />}>
            {routes.reduce((acc, { Component, path, children, name }) => {
              const currentSetting = settings.find(({ key }) => key === name);

              if (!currentSetting || currentSetting?.status === 1) {
                const currentRoute = children?.length ? (
                  <Route key={path} path={path} element={<Component />}>
                    {children.map(({ Component: ChildComponent, path: childPath }) => (
                      <Route key={childPath} path={`${path}${childPath}`} element={<ChildComponent />} />
                    ))}
                  </Route>
                ) : (
                  <Route key={path} path={path} element={<Component />} />
                );

                acc.push(currentRoute);
              }

              return acc;
            }, [])}
          </Route>
        </Routes>
      </main>
      <ToastBar />
    </div>
  );
}

export default App;
