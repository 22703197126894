import { memo } from 'react';

import Badge from 'components/badge';
import { useTranslator } from 'utils/translator';

const MenuLabel = ({ label, children }) => {
  const { t } = useTranslator();
  const menuName = label.split('[LABEL]');

  return (
    <>
      {t(menuName[0])} {menuName.length > 1 && <Badge status="primary">{children}</Badge>}
    </>
  );
};

export default memo(MenuLabel);
