import { lazy } from 'react';

import { getRouteName, withSuspense } from 'utils';
import {
  ACCESSIBILITY,
  ACCOUNT,
  CALENDAR,
  FACILITIES,
  HELP_CENTER,
  MEMBERSHIP,
  NOT_FOUND,
  NOTIFICATION,
  PATIENTS,
  PLATFORM,
  PRIVACY,
  PROFILE,
  REFERRALS,
  SCHEDULE,
  SECURITY,
  SETTINGS,
  SMART_CONNECT,
  TASKS,
  TRANSCRIPTIONS,
  WALLET,
} from 'constants/path';

export const routes = [
  {
    path: '/',
    name: 'dashboard',
    Component: withSuspense(lazy(() => import('pages/main'))),
  },
  {
    path: SETTINGS,
    name: getRouteName(SETTINGS),
    Component: withSuspense(lazy(() => import('pages/settings'))),
    children: [
      {
        path: PROFILE,
        name: getRouteName(PROFILE),
        Component: withSuspense(lazy(() => import('pages/settings/profileCompliance'))),
      },
      {
        path: ACCOUNT,
        name: getRouteName(ACCOUNT),
        Component: withSuspense(lazy(() => import('pages/settings/account'))),
      },
      {
        path: PLATFORM,
        name: getRouteName(PLATFORM),
        Component: withSuspense(lazy(() => import('pages/settings/platform'))),
      },
      {
        path: SECURITY,
        name: getRouteName(SECURITY),
        Component: withSuspense(lazy(() => import('pages/settings/security'))),
      },
      {
        path: PRIVACY,
        name: getRouteName(PRIVACY),
        Component: withSuspense(lazy(() => import('pages/settings/privacy'))),
      },
      {
        path: NOTIFICATION,
        name: getRouteName(NOTIFICATION),
        Component: withSuspense(lazy(() => import('pages/settings/notification'))),
      },
      {
        path: ACCESSIBILITY,
        name: getRouteName(ACCESSIBILITY),
        Component: withSuspense(lazy(() => import('pages/settings/notification'))),
      },
    ],
  },
  {
    path: SMART_CONNECT,
    name: getRouteName(SMART_CONNECT),
    Component: withSuspense(lazy(() => import('pages/smartConnect'))),
    children: [
      {
        path: HELP_CENTER,
        name: getRouteName(HELP_CENTER),
      },
    ],
  },
  {
    path: SCHEDULE,
    name: getRouteName(SCHEDULE),
    Component: withSuspense(lazy(() => import('pages/schedule'))),
    children: [
      {
        path: '/2',
        name: getRouteName(SCHEDULE),
      },
    ],
  },
  {
    path: TRANSCRIPTIONS,
    name: getRouteName(TRANSCRIPTIONS),
    Component: withSuspense(lazy(() => import('pages/transcriptions'))),
  },
  {
    path: CALENDAR,
    name: getRouteName(CALENDAR),
    Component: withSuspense(lazy(() => import('pages/calendar'))),
  },
  {
    path: FACILITIES,
    name: getRouteName(FACILITIES),
    Component: withSuspense(lazy(() => import('pages/facilities'))),
  },
  {
    path: PATIENTS,
    name: getRouteName(PATIENTS),
    Component: withSuspense(lazy(() => import('pages/patients'))),
  },
  {
    path: WALLET,
    name: getRouteName(WALLET),
    Component: withSuspense(lazy(() => import('pages/wallet'))),
  },
  {
    path: MEMBERSHIP,
    name: getRouteName(MEMBERSHIP),
    Component: withSuspense(lazy(() => import('pages/membership'))),
  },
  {
    path: REFERRALS,
    name: getRouteName(REFERRALS),
    Component: withSuspense(lazy(() => import('pages/referrals'))),
  },
  {
    path: TASKS,
    name: getRouteName(TASKS),
    Component: withSuspense(lazy(() => import('pages/tasks'))),
  },
  {
    path: NOT_FOUND,
    name: getRouteName(NOT_FOUND),
    Component: withSuspense(lazy(() => import('pages/404'))),
  },
];
