import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { getCookie } from 'utils';
import { SIGN_IN_PATH } from 'constants/path';

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, statusCode }) => {
      if (statusCode === 401) {
        window.location.href = SIGN_IN_PATH;
      }
    });
  return forward(operation);
});

const idmLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URI}/graphql`,
});

const opsLink = createHttpLink({
  uri: `${process.env.REACT_APP_OPS_API_URI}/graphql`,
});

const calyxLink = createHttpLink({
  uri: `${process.env.REACT_APP_CALYX_URI}/graphql`,
});

const navixLink = createHttpLink({
  uri: `${process.env.REACT_APP_NAVIX_URI}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const accessToken = getCookie('accessToken');
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  };
});

export const idmClient = new ApolloClient({
  link: authLink.concat(errorLink).concat(idmLink),
  cache: new InMemoryCache(),
});

export const opsClient = new ApolloClient({
  link: authLink.concat(errorLink).concat(opsLink),
  cache: new InMemoryCache(),
});

export const calyxClient = new ApolloClient({
  link: authLink.concat(errorLink).concat(calyxLink),
  cache: new InMemoryCache(),
});

export const navixClient = new ApolloClient({
  link: authLink.concat(errorLink).concat(navixLink),
  cache: new InMemoryCache(),
});
