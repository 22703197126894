import { NavLink } from 'react-router-dom';

const DynamicLink = ({ to, children, ...rest }) =>
  to ? (
    <NavLink to={to} {...rest}>
      {children}
    </NavLink>
  ) : (
    <div {...rest}>{children}</div>
  );

export default DynamicLink;
