import { createUseStyles } from 'react-jss';

import { generateStyles } from 'assets/styles/helpers';

export const useStyles = createUseStyles(theme => ({
  h1: generateStyles(81, 96, -0.25),
  h2: generateStyles(72, 88, -0.25),
  h3: generateStyles(64, 80, -0.25),
  h4: generateStyles(57, 72, -0.25),
  h5: generateStyles(45, 56),
  h6: generateStyles(36, 44),
  headlineL: generateStyles(32, 40),
  headlineM: generateStyles(28, 36),
  headlineS: generateStyles(24, 32),
  titleXL: generateStyles(22, 28),
  titleL: generateStyles(20, 28),
  titleM: generateStyles(16, 24),
  titleS: generateStyles(14, 20),
  bodyXXL: generateStyles(20, 28),
  bodyXL: generateStyles(18, 26),
  bodyL: generateStyles(16, 24),
  bodyM: generateStyles(14, 20),
  bodyS: generateStyles(12, 16),
  labelXXL: generateStyles(18, 26),
  labelXL: generateStyles(16, 24),
  labelL: generateStyles(14, 20),
  labelM: generateStyles(12, 16),
  labelS: generateStyles(11, 16),
  bold: {
    fontWeight: 500,
  },
  error: {
    color: theme.palette.error,
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
