import { memo, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import clsx from 'clsx';

import DrawerHeader from 'components/drawer/DrawerHeader';
import { ReactComponent as CloseIcon } from 'assets/icons/Close.svg';

import { useStyles } from './styles';

const Drawer = ({
  open,
  title,
  onClose,
  children,
  size = 'md',
  description,
  side = 'right',
  wrapperClassName,
  panelClassName,
  showClose = true,
  extraContent,
}) => {
  const {
    dialog,
    overlay,
    dialogBody,
    dialogBodyWrapper,
    transitionEnter,
    transitionEnterFrom,
    transitionEnterTo,
    dialogPanel,
    dialogPanelWrapper,
    overlayEnter,
    overlayEnterFrom,
    overlayEnterTo,
    dialogContent,
    arrowIcon,
  } = useStyles({ side, size });

  const touchstartX = useRef(0);
  const touchendX = useRef(0);
  const [drawerRef, setDrawerRef] = useState(null);

  const onTouchStart = useCallback(e => {
    touchstartX.current = e.changedTouches[0].screenX;
  }, []);

  const handleGesture = useCallback(() => {
    if (touchendX.current > touchstartX.current + 50) {
      onClose();
    }
  }, [onClose]);

  const onTouchEnd = useCallback(
    e => {
      touchendX.current = e.changedTouches[0].screenX;
      handleGesture();
    },
    [handleGesture],
  );

  useLayoutEffect(() => {
    if (!open || !drawerRef) return;

    drawerRef.addEventListener('touchstart', onTouchStart);
    drawerRef.addEventListener('touchend', onTouchEnd);

    return () => {
      if (drawerRef) {
        drawerRef.removeEventListener('touchstart', onTouchStart);
        drawerRef.removeEventListener('touchend', onTouchEnd);
      }
    };
  }, [open, onTouchStart, onTouchEnd, drawerRef]);

  return (
    <Transition show={open}>
      <Dialog className={dialog} onClose={onClose}>
        <TransitionChild
          enter={overlayEnter}
          enterFrom={overlayEnterFrom}
          enterTo={overlayEnterTo}
          leave={overlayEnter}
          leaveFrom={overlayEnterTo}
          leaveTo={overlayEnterFrom}
        >
          <div className={overlay} onClick={onClose} />
        </TransitionChild>
        <div className={dialogBody}>
          <div className={dialogBodyWrapper}>
            <div>
              <TransitionChild
                enter={transitionEnter}
                enterFrom={transitionEnterFrom}
                enterTo={transitionEnterTo}
                leave={transitionEnter}
                leaveFrom={transitionEnterTo}
                leaveTo={transitionEnterFrom}
              >
                <DialogPanel className={clsx(dialogPanel, panelClassName)}>
                  <div
                    className={clsx(dialogPanelWrapper, wrapperClassName)}
                    ref={ref => {
                      setDrawerRef(ref);
                    }}
                  >
                    {(title || description) && <DrawerHeader size={size} title={title} description={description} />}

                    {showClose && (
                      <div className={arrowIcon} onClick={onClose}>
                        <CloseIcon />
                      </div>
                    )}
                    <div className={dialogContent}>{children}</div>
                  </div>
                  {extraContent && extraContent}
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default memo(Drawer);
