import { memo } from 'react';
import clsx from 'clsx';
import { NavLink, useLocation } from 'react-router-dom';

import Badge from 'components/badge';
import Typography from 'components/typography';
import { useTranslator } from 'utils/translator';
import { ReactComponent as ChevronRight } from 'assets/icons/Chevron Right.svg';

import MenuLabel from './MenuLabel';
import DynamicLink from './DynamicLink';
import { useStyles } from './styles';

const MenuItem = ({ Icon, label, href, subMenus, className, setting, ...rest }) => {
  const { t } = useTranslator();
  const { menuItem, menuIcon, subMenuWrapper, subMenuList, subMenuItem, arrowIcon, menuText } = useStyles();
  const location = useLocation();
  const hasActiveChild = () => {
    return subMenus?.some(sub => location.pathname === sub.href);
  };

  if (!subMenus || !subMenus.length) {
    return (
      <NavLink to={href} className={({ isActive }) => clsx(className, menuItem, { current: isActive })} {...rest}>
        <Icon className={menuIcon} />
        <Typography variant="bodyM" className={menuText}>
          <MenuLabel label={label}>{/* Must Be a label */}</MenuLabel>
          {!!setting?.new_tag && <Badge status="primary">{t('new')}</Badge>}
        </Typography>
      </NavLink>
    );
  }

  return (
    <DynamicLink to={href} className={clsx(className, menuItem, { current: hasActiveChild() })} {...rest}>
      <div className={menuIcon}>
        <Icon />
      </div>
      <Typography variant="bodyM" className={menuText}>
        <MenuLabel label={label}>{/* Must ba a correct badge value */}3</MenuLabel>
      </Typography>
      <ChevronRight className={arrowIcon} />
      <div className={subMenuWrapper}>
        <div className={subMenuList}>
          {subMenus.map(sub => (
            <NavLink
              className={({ isActive }) => clsx(subMenuItem, { current: isActive })}
              to={sub.href}
              key={sub.label}
            >
              <span>{t(sub.label)}</span>
              {!!sub.setting?.new_tag && <Badge status="primary">{t('new')}</Badge>}
            </NavLink>
          ))}
        </div>
      </div>
    </DynamicLink>
  );
};

export default memo(MenuItem);
