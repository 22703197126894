import { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';

const useForm = ({ schema, defaultState, callback }) => {
  const [formData, setFormData] = useState(defaultState);
  const [errors, setErrors] = useState({});

  const handleFormUpdate = useCallback(field => {
    setFormData(prev => ({ ...prev, ...field }));
    callback?.();
  }, []);

  const validateField = useCallback(async (name, value) => {
    try {
      await yup.reach(schema, name).validate(value);
      setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    } catch (error) {
      setErrors(prevErrors => ({ ...prevErrors, [name]: error.message }));
    }
  }, []);

  useEffect(() => {
    Object.entries(formData).forEach(([name, value]) => validateField(name, value));
  }, [formData]);

  const reset = useCallback(() => {
    setFormData(defaultState);
    setErrors({});
  }, [defaultState]);

  return { formData, errors, handleFormUpdate, reset };
};

export default useForm;
