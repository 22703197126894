import axios from 'connection/axios';

const uploadFiles = async ({ url, files, purpose = 'message', handleUploadProgress }) => {
  const uploads = files.map(file => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('purpose', purpose);

    return axios.post(url, formData, { onUploadProgress: handleUploadProgress });
  });

  return Promise.all(uploads);
};

export default uploadFiles;
